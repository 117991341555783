import axios from "axios";
import { Constants } from "../../Constants";

const getLogoWebBase64 = async () => {
    try {
        return await axios.get(`${Constants.BASE_URL}logo_web_base64`);
    } catch (error) {
        throw error;
    }
}

export async function generateImageForFB(originalImageUrl, lotId, title, description, location, callback) {
    // Crear un elemento canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Crear un objeto Image para la imagen original
    const originalImage = new Image();
    originalImage.src = originalImageUrl;
    originalImage.crossOrigin = "anonymous"

    originalImage.onload = async () => {
        // Establecer dimensiones del canvas iguales a la imagen original más espacio para el texto
        const textHeight = 250; // Altura total deseada para el área de título y descripción
        canvas.width = originalImage.width;
        canvas.height = originalImage.height + textHeight; // Añadir espacio para el texto

        // Dibujar la imagen original en el canvas
        ctx.drawImage(originalImage, 0, 0);

        // Crear otro objeto Image para la marca de agua
        const watermarkImage = new Image();
        const logoWebBase64 = await getLogoWebBase64();
        watermarkImage.src = logoWebBase64.data.data.logo_web;

        watermarkImage.onload = () => {
            // Dibujar el fondo de texto debajo de la imagen para el título y descripción
            ctx.fillStyle = '#389611'; // Color de fondo para el texto
            ctx.fillRect(0, originalImage.height, canvas.width, textHeight);

            // Ajustar el tamaño de la fuente del título
            let titleFontSize = 24; // Tamaño inicial de la fuente del título
            ctx.font = `${titleFontSize}px Arial`;

            // Reducir el tamaño de la fuente si el título es más ancho que el canvas
            while (ctx.measureText(title).width > canvas.width - 20) { // Deja un margen de 20px
                titleFontSize--;
                ctx.font = `${titleFontSize}px Arial`;
            }

            // Dibujar el título alineado a la izquierda, con margen superior
            ctx.fillStyle = 'white';
            ctx.textAlign = 'left'; // Alineación a la izquierda
            const titleY = originalImage.height + titleFontSize + 10; // 10px de margen superior
            ctx.fillText(`Lote ${lotId} - ${title}`, 10, titleY); // 10px de margen desde el borde izquierdo

            // Ajustar el tamaño de la fuente para la descripción
            let descriptionFontSize = 16; // Tamaño inicial de la fuente de la descripción
            ctx.font = `${descriptionFontSize}px Arial`;

            // Reducir el tamaño de la fuente si la descripción es más ancha que el canvas
            while (ctx.measureText(description).width > canvas.width - 20) {
                descriptionFontSize--;
                ctx.font = `${descriptionFontSize}px Arial`;
            }

            // Dibujar la descripción debajo del título alineada a la izquierda
            const descriptionY = titleY + descriptionFontSize + 10; // Margen 10px entre título y descripción
            ctx.fillText(description, 10, descriptionY); // Margen 10px desde la izquierda

            // Dibujar el texto de ubicación en la esquina inferior izquierda
            ctx.textAlign = 'left';
            ctx.font = '16px Arial'; // Tamaño de fuente fijo para el texto de ubicación
            ctx.fillText(location || '', 10, canvas.height - 10); // Ajustado con 10px de margen desde la izquierda

            // Ajustar el tamaño de la marca de agua (logo)
            const scale = 0.15; // Ajustar este valor al porcentaje deseado del ancho de la imagen principal
            const watermarkWidth = originalImage.width * scale;
            const watermarkHeight = watermarkImage.height * (watermarkWidth / watermarkImage.width);

            // Posicionar el logo abajo a la derecha del canvas completo (incluyendo la parte de texto)
            const x = canvas.width - watermarkWidth - 10; // 10px desde el borde derecho
            const y = canvas.height - watermarkHeight - 10; // 10px desde el borde inferior del canvas

            // Dibujar la marca de agua (logo) en el canvas
            ctx.drawImage(watermarkImage, x, y, watermarkWidth, watermarkHeight);

            // Convertir el canvas en un Blob
            canvas.toBlob((blob) => {
                // Crear un nuevo archivo con el Blob resultante
                const fileWithWatermark = new File([blob], `lote_${lotId}_${Date.now()}.jpeg`, { type: 'image/jpeg' });
                callback(fileWithWatermark);
            }, 'image/jpeg');
        };
    };
}