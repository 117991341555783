import React, { Component } from 'react';
import './ModalCustomer.scss';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Table, Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import { logoutAction } from '../../redux/actions/UserActions';
import { departaments, generateLoginCode, getCustomer, updateCustomer, verificarCI, verificarRUT, verificateDNI } from '../../services/UserServices';
import { Constants } from '../../Constants';
import { Link } from 'react-router-dom';

class ModalCustomer extends Component {
    state = {
        departaments: [],
        departamentSelect: 10,
        email: '',
        name: '',
        phone: '',
        telephone: '',
        document: '',
        address: '',
        messageError: '',
        password: '',
        login_code: '',
        customerId: 0,
        idUser: null,
        expediente: '',
        type_document: Constants.TYPE_DOCUMENT_OTHER,
        city: '',
        activeTab: 1,
        newPassword: '*********',
        fb: false,
        page: 1,
        pages: 0,
        status: 0,
        reason: '',
        notes: '',
        invoice_name: '',
        invoice_type_document: Constants.TYPE_DOCUMENT_RUT,
        invoice_document: '',
    }

    componentDidMount = async () => {
        toast.configure();
        this.fetchDepartamens();
        await this.setState({
            customerId: this.props.customerId
        });
        if (this.state.customerId !== 0) {
            this.fetchCustomer();
        }
    }

    fetchCustomer = async () => {
        try {
            let response = await getCustomer({
                token: this.props.sessionProps.account.token,
                customerId: this.state.customerId
            });
            await this.setState({
                ...response.data.data.user,
                departamentSelect: response.data.data.user.id_departament
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchDepartamens = async () => {
        try {
            let response = await departaments();
            await this.setState({ departaments: response.data.data.departaments });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    saveAccount = async (e) => {
        e.preventDefault();
        await this.setState({ loading: true, messageError: '' })
        if (this.state.expediente === '') {
            if (this.state.type_document === Constants.TYPE_DOCUMENT_CI) {
                let isValid = await verificarCI(this.state.document);
                if (!isValid) {
                    await this.setState({ messageError: 'La CI ingresada no tiene un formato válido', loading: false });
                    return;
                }
            }
            if (this.state.type_document === Constants.TYPE_DOCUMENT_RUT) {
                let isValid = await verificarRUT(this.state.document);
                if (!isValid) {
                    await this.setState({ messageError: 'El RUT ingresado no tiene un formato válido', loading: false });
                    return;
                }
            }
            if (this.state.type_document === Constants.TYPE_DOCUMENT_DNI) {
                let isValid = await verificateDNI(this.state.document);
                if (!isValid) {
                    await this.setState({ messageError: 'El DNI ingresado no tiene un formato válido', loading: false });
                    return;
                }
            }
        }
        try {
            let data = {
                token: this.props.sessionProps.account.token,
                email: this.state.email,
                customerId: this.state.customerId,
                address: this.state.address,
                document: this.state.document,
                id_departament: this.state.departamentSelect,
                name: this.state.name,
                phone: this.state.phone,
                telephone: this.state.telephone,
                password: this.state.password,
                forceRemitter: this.props.forceRemitter ? true : false,
                expediente: this.state.expediente,
                type_document: this.state.type_document === null ? '' : this.state.type_document,
                city: this.state.city,
                login_code: this.state.login_code,
                reason: this.state.reason,
                notes: this.state.notes,
                invoice_name: this.state.invoice_name,
                invoice_type_document: this.state.invoice_type_document,
                invoice_document: this.state.invoice_document,
            };
            if (this.state.newPassword !== '*********') {
                data.newPassword = this.state.newPassword;
            }
            let response = await updateCustomer(data);
            toast(`${this.props.forceRemitter ? 'Remitente' : 'Cliente'} #${response.data.data.customer.id} ${this.state.customerId === 0 ? 'creado' : 'actualizado'} con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            await this.setState({ loading: false, customerId: response.data.data.customer.id, idUser: response.data.data.customer.id_user })
        } catch (error) {
            await this.setState({ loading: false })
            if (error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/ingresar');
            }
            else {
                let msg = 'Ocurrió un error, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleChange = (event, maxLength = null) => {
        const { name, value } = event.target;
        this.setState({
            [name]: maxLength ? value.slice(0, this.getMaxLength()) : value
        });
    }

    close = () => {
        this.props.close({
            name: this.state.name,
            id: this.state.customerId,
            id_user: this.state.idUser
        });
    }

    getMaxLength = () => {
        if (this.state.type_document == Constants.TYPE_DOCUMENT_CI) {
            return 8;
        }
        if (this.state.type_document == Constants.TYPE_DOCUMENT_RUT) {
            return 12;
        }
        return 50;
    }

    toggleTab = async (tab) => {
        if (this.state.activeTab !== tab) {
            await this.setState({
                activeTab: tab
            });
        }
    }

    generateNewPassword = async () => {
        await this.setState({
            newPassword: Math.random().toString(36).substring(7)
        });
    }

    generateNewLoginCode = async () => {
        await this.setState({ loading: true })
        try {
            let response = await generateLoginCode({
                token: this.props.sessionProps.account.token,
                id: this.state.customerId
            });
            await this.setState({ loading: false, login_code: response.data.data.loginCode });
        } catch (error) {
            await this.setState({ loading: false })
            if (error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/ingresar');
            }
            else {
                let msg = 'Ocurrió un error, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    render() {
        return (
            <div className="container-modal">
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                            X
                                        </div>
                                        <div>
                                            <h4>{this.state.customerId === 0 ? 'Agregar' : 'Modificar'} {this.props.forceRemitter ? 'remitente' : 'cliente'}</h4>
                                        </div>
                                        <Nav tabs className="nav-tabs-custom mb-4">
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab(1); }} className={`${this.state.activeTab === 1 && 'active'} font-weight-bold p-3`}>General</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab(2); }} className={`${this.state.activeTab === 2 && 'active'} font-weight-bold p-3`}>Facturación</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab(3); }} className={`${this.state.activeTab === 3 && 'active'} font-weight-bold p-3`}>Estado</NavLink>
                                            </NavItem>
                                        </Nav>
                                        <form className="signup-form" onSubmit={this.saveAccount} style={{ marginTop: '2rem' }}>
                                            <TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">
                                                <TabPane tabId={1}>
                                                    <div className="form-group">
                                                        <label>Nombre Completo {this.state.expediente !== '' ? '' : '(*)'}</label>
                                                        <input type="text" className="form-control" placeholder="Ingrese nombre completo" name="name"
                                                            value={this.state.name} onChange={this.handleChange} required={this.state.expediente !== '' ? false : true} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Expediente</label>
                                                        <input type="text" className="form-control" placeholder="Ingrese expediente" name="expediente"
                                                            value={this.state.expediente} onChange={this.handleChange} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input type="email" className="form-control" placeholder="Ingrese email" name="email"
                                                            value={this.state.email} onChange={this.handleChange} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Celular</label>
                                                        <input type="text" className="form-control" placeholder="Ingrese celular" name="phone"
                                                            value={this.state.phone} onChange={this.handleChange} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Teléfono</label>
                                                        <input type="text" className="form-control" placeholder="Ingresar teléfono" name="telephone"
                                                            value={this.state.telephone} onChange={this.handleChange} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Documento {this.state.expediente !== '' ? '' : '(*)'}</label>
                                                        <div style={{ display: 'flex' }}>
                                                            <select className="form-control" name="type_document" onChange={this.handleChange}
                                                                style={{ width: 'max-content', marginRight: '0.1rem' }}>
                                                                {
                                                                    window.api_name === 'amichelon_subastas' ?
                                                                        <option value={Constants.TYPE_DOCUMENT_DNI}
                                                                            selected={this.state.type_document === Constants.TYPE_DOCUMENT_DNI ? true : false}>DNI</option>
                                                                        : (
                                                                            <>
                                                                                <option value={Constants.TYPE_DOCUMENT_CI}
                                                                                    selected={this.state.type_document === Constants.TYPE_DOCUMENT_CI ? true : false}>CI</option>
                                                                                <option value={Constants.TYPE_DOCUMENT_RUT}
                                                                                    selected={this.state.type_document === Constants.TYPE_DOCUMENT_RUT ? true : false}>RUT</option>
                                                                            </>
                                                                        )
                                                                }
                                                                <option value={Constants.TYPE_DOCUMENT_OTHER}
                                                                    selected={this.state.type_document === Constants.TYPE_DOCUMENT_OTHER ? true : false}>Otro</option>
                                                            </select>
                                                            <input type="text" className="form-control" placeholder="Ingrese documento" name="document"
                                                                value={this.state.document} onChange={(e) => this.handleChange(e, true)} />
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Dirección</label>
                                                        <input type="text" className="form-control" placeholder="Ingrese dirección" name="address"
                                                            value={this.state.address} onChange={this.handleChange} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Ciudad</label>
                                                        <input type="text" className="form-control" placeholder="Ingrese la ciudad" name="city"
                                                            value={this.state.city} onChange={this.handleChange} />
                                                    </div>

                                                    {
                                                        this.props.configAccount.config?.show_department ?
                                                            <div className="form-group">
                                                                {
                                                                    window.api_name === 'amichelon_subastas' ?
                                                                        <label>Provincia</label>
                                                                        :
                                                                        <label>Departamento</label>
                                                                }
                                                                <div className="select-box">
                                                                    <select className="form-control" name="departamentSelect" onChange={this.handleChange} value={this.state.departamentSelect}>
                                                                        {
                                                                            this.state.departaments.map(item =>
                                                                                <option value={item.id}>{item.name}</option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }

                                                    {
                                                        this.state.customerId === 0 &&
                                                        <div className="form-group">
                                                            <label>Contraseña</label>
                                                            <input type="text" className="form-control" placeholder="Ingrese contraseña" name="password"
                                                                value={this.state.password} onChange={this.handleChange} />
                                                        </div>

                                                    }
                                                    {
                                                        this.state.customerId !== 0 && !this.state.fb &&
                                                        <div className="form-group">
                                                            <label>Contraseña</label>
                                                            <div style={{ display: 'flex', cursor: 'pointer' }}>
                                                                <input type="text" className="form-control" placeholder="Contraseña" name="newPassword"
                                                                    value={this.state.newPassword} onChange={this.handleChange} required />
                                                                <Link onClick={() => this.generateNewPassword()} className="text-success" style={{ width: '10%' }}>
                                                                    <i className="mdi mdi-plus-circle font-size-22" style={{ marginLeft: '5px' }}></i>
                                                                    <label>Generar</label>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.customerId !== 0 &&
                                                        <div className="form-group">
                                                            <label>Código de ingreso</label>
                                                            <div style={{ display: 'flex', cursor: 'pointer' }}>
                                                                <input type="text" className="form-control" name="login_code"
                                                                    value={this.state.login_code} disabled />
                                                                <Link onClick={() => this.generateNewLoginCode()} className="text-success" style={{ width: '10%' }}>
                                                                    <i className="mdi mdi-plus-circle font-size-22" style={{ marginLeft: '5px' }}></i>
                                                                    <label>Generar</label>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    }

                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    <div className="form-group">
                                                        <label>Nombre o Razón social</label>
                                                        <input type="text" className="form-control" placeholder="Ingrese nombre completo" name="invoice_name"
                                                            value={this.state.invoice_name} onChange={this.handleChange} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Documento</label>
                                                        <div style={{ display: 'flex' }}>
                                                            <select className="form-control" name="invoice_type_document" onChange={this.handleChange}
                                                                style={{ width: 'max-content', marginRight: '0.1rem' }} value={this.state.invoice_type_document}>
                                                                {
                                                                    window.api_name === 'amichelon_subastas' ?
                                                                        <option value={Constants.TYPE_DOCUMENT_DNI}
                                                                            selected={this.state.type_document === Constants.TYPE_DOCUMENT_DNI ? true : false}>DNI</option>
                                                                        : (
                                                                            <>
                                                                                <option value={Constants.TYPE_DOCUMENT_CI}
                                                                                    selected={this.state.type_document === Constants.TYPE_DOCUMENT_CI ? true : false}>CI</option>
                                                                                <option value={Constants.TYPE_DOCUMENT_RUT}
                                                                                    selected={this.state.type_document === Constants.TYPE_DOCUMENT_RUT ? true : false}>RUT</option>
                                                                            </>
                                                                        )
                                                                }
                                                                <option value={Constants.TYPE_DOCUMENT_OTHER}
                                                                    selected={this.state.type_document === Constants.TYPE_DOCUMENT_OTHER ? true : false}>Otro</option>
                                                            </select>
                                                            <input type="text" className="form-control" placeholder="Ingrese documento" name="invoice_document"
                                                                value={this.state.invoice_document} onChange={(e) => this.handleChange(e, true)} />
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Notas: </label>
                                                        <textarea className="form-control" placeholder="Ingrese notas" name="notes" rows={10}
                                                            value={this.state.notes} onChange={this.handleChange} />
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={3}>
                                                    <div className="form-group">
                                                        <label>Estado:&nbsp;</label>
                                                        {
                                                            this.state.status ?
                                                                <label className='text-success'>
                                                                    Activo
                                                                </label> :
                                                                <label className='text-danger'>
                                                                    Bloqueado
                                                                </label>
                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Razón de bloqueo: </label>
                                                        <textarea className="form-control" placeholder="Razón de bloqueo" name="reason" rows={10}
                                                            value={this.state.reason} onChange={this.handleChange} />
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                            <div style={{ textAlign: 'center', color: 'red' }}>
                                                <label>{this.state.messageError}</label>
                                            </div>
                                            <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                                <div>
                                                    <button onClick={() => this.close()} type="button" className="btn btn-secondary">Cerrar</button>
                                                    <button style={{ marginLeft: '0.5rem' }} type="submit" className="btn btn-primary">Guardar</button>
                                                </div>
                                            </FormGroup>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCustomer);